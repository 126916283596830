import { AppTypes } from './appTypes.ts';

interface DigitalTwinSection {
    title: string;
    description: string;
    bulletPoints: string[];
}

interface OverviewPage {
    pageTitle: string;
    digitalTwinSection: DigitalTwinSection;
}

interface AveragesPerEndpoint {
    title: string;
}

interface FinancialsPage {
    averagesPerEndpoint: AveragesPerEndpoint;
}

interface AppText {
    overviewPage: OverviewPage;
    financialsPage: FinancialsPage;
}

export interface AppTexts {
    [AppTypes.GLOBAL_FABRIC]: AppText;
    [AppTypes.MIST]: AppText;
}

export const AppTexts: AppTexts = {
    [AppTypes.GLOBAL_FABRIC]: {
        overviewPage: {
            pageTitle: 'Global Fabric Digital Twin Expense Forecasts',
            digitalTwinSection: {
                title: 'Global Fabric Digital Twin',
                description:
                    'The real-time dashboard displays a daily-updated digital twin simulation of current and future Global Fabric network expenses. It provides a view based on:',
                bulletPoints: [
                    'The current network configuration',
                    'Current Global Fabric Pricing',
                    'Forecasts for traffic growth using measured traffic data',
                ],
            },
        },
        financialsPage: {
            averagesPerEndpoint: {
                title: 'Averages per Global Fabric site',
            },
        },
    },
    [AppTypes.MIST]: {
        overviewPage: {
            pageTitle: 'Digital Twin Expense Forecasts',
            digitalTwinSection: {
                title: 'Digital Twin',
                description:
                    'The real-time dashboard displays a daily-updated digital twin simulation of current and future network expenses. It provides a view based on:',
                bulletPoints: [
                    'The current network configuration',
                    'Current network pricing',
                    'Estimated labor expenses',
                    'Forecasts for traffic growth using measured traffic data',
                ],
            },
        },
        financialsPage: {
            averagesPerEndpoint: {
                title: 'Averages per site',
            },
        },
    },
};

export function getAppTexts():
    | AppTexts[AppTypes.GLOBAL_FABRIC]
    | AppTexts[AppTypes.MIST] {
    const appType = localStorage.getItem('appType') as AppTypes;

    if (!appType || !AppTexts[appType]) {
        throw new Error('Invalid or nonexistent app type');
    }

    return AppTexts[appType];
}

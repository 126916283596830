import React from 'react';
import { AiOutlineDisconnect } from 'react-icons/ai';
import { RxCalendar } from 'react-icons/rx';
import { useHistory } from 'react-router-dom';

import { RouteMap } from '@bae/routes';

import { getAppTexts } from '@/app-config/appSpecializationTexts.ts';
import LatestUpdateIllustration from '@/assets/latest-update.svg';
import { Button } from '@/components/ui/button.tsx';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import { useDigitalTwin } from '@/features/digital-twin/api/get-digital-twin.ts';

const LatestUpdateDateBadge = ({ loading }: { loading: boolean }) => {
    if (loading)
        return (
            <Skeleton className='mb-4 h-[34px] w-[220px] rounded-md bg-newDesign-divider' />
        );

    return (
        <div className='mb-4 flex w-fit gap-1 rounded-lg border border-newDesign-divider bg-white p-2'>
            <RxCalendar className='size-4 text-newDesign-black' />
            <p className='text-xs text-newDesign-black'>
                Last automatic update: 08/07/2024
            </p>
        </div>
    );
};

const LatestUpdateCard = () => {
    const history = useHistory();

    const digitalTwinQuery = useDigitalTwin({});
    const { data, isLoading, isFetching } = digitalTwinQuery;

    const navigateToDashboard = () => {
        if (isLoading || isFetching) return;
        history.push(
            `${RouteMap.scenarios.path}/${data.id}/${RouteMap.kpis.path}`,
        );
    };
    return (
        <div className='grid h-full w-full grid-cols-2 grid-rows-1 md:grid-cols-3'>
            <div className='col-span-2 row-span-1 flex flex-col justify-between'>
                <div className='flex flex-col gap-4'>
                    <h2 className='text-lg font-semibold text-newDesign-black'>
                        {getAppTexts().overviewPage.digitalTwinSection.title}
                    </h2>
                    <p className='text-base text-newDesign-black'>
                        {
                            getAppTexts().overviewPage.digitalTwinSection
                                .description
                        }
                    </p>
                    <div className='flex flex-wrap gap-2'>
                        {getAppTexts().overviewPage.digitalTwinSection.bulletPoints.map(
                            (item) => (
                                <p
                                    className='text-nowrap rounded-md border bg-white px-2 py-1'
                                    key={item}>
                                    {item}
                                </p>
                            ),
                        )}
                    </div>
                    <LatestUpdateDateBadge loading={isLoading || isFetching} />
                </div>
                <div className='mt-1 flex w-full flex-col gap-4 sm:w-fit sm:flex-row'>
                    <Button disabled withIcon onClick={navigateToDashboard}>
                        <AiOutlineDisconnect className='mr-2 size-5' />
                        Dashboard (Not connected)
                    </Button>
                </div>
            </div>
            <div className='col-span-1 row-span-1 hidden h-full w-full items-center justify-center md:flex'>
                <LatestUpdateIllustration />
            </div>
        </div>
    );
};

export default LatestUpdateCard;
